
export default function CivBonuses() {
	document.title = "Age of Empires 4 Civilisation Bonuses";

	return (
		<div className="max-w-screen-xl mx-auto py-10 px-3">
			AOE4 Civ Bonuses

			<img src="/civs.png" className="my-5" />
		</div>
	);
}
